import React, { useEffect, useState } from 'react';
import { projectsData } from './Data'; // importar dados dos projetos de DATA
import { projectsNav } from './Data'; // importar tipo dos projetos de DATA
import WorksItems from './WorksItems';

const Works = () => {
    const [item, setItem] = useState({ name: 'Todos' });
    const [projects, setProjects] = useState([]);
    const [active, setActive] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 4; // Defina o número de itens por página

    useEffect(() => {
        if (item.name === 'Todos') {
            setProjects(projectsData);
        } else {
            const newProjects = projectsData.filter((project) => {
                return project.category === item.name;
            });
            setProjects(newProjects);
        }
    }, [item]);

    const handleClick = (e, index) => {
        setItem({ name: e.target.textContent });
        setActive(index);
        setCurrentPage(1); // Reinicia a página ao selecionar uma nova categoria
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentProjects = projects.slice(indexOfFirstItem, indexOfLastItem);

    const nextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const prevPage = () => {
        setCurrentPage((prevPage) => prevPage - 1);
    };

    const goToPage = (page) => {
        setCurrentPage(page);
    };

    return (
        <div>
            <div className="work__filters">
                {projectsNav.map((item, index) => {
                    return (
                        <span
                            onClick={(e) => {
                                handleClick(e, index);
                            }}
                            className={`${active === index ? 'active-work' : ''} work__item`}
                            key={index}
                        >
                            {item.name}
                        </span>
                    );
                })}
            </div>

            <div className="work__container container grid">
                {currentProjects.map((item) => {
                    return <WorksItems item={item} key={item.id} />;
                })}
            </div>

            <div className="pagination">
                <button onClick={prevPage} disabled={currentPage === 1}>
                    Anterior
                </button>
                {Array.from({ length: Math.ceil(projects.length / itemsPerPage) }).map((_, index) => (
                    <button
                        key={index}
                        onClick={() => goToPage(index + 1)}
                        className={currentPage === index + 1 ? 'active' : ''}
                    >
                        {index + 1}
                    </button>
                ))}
                <button
                    onClick={nextPage}
                    disabled={currentPage === Math.ceil(projects.length / itemsPerPage)}
                >
                    Próxima
                </button>
            </div>
        </div>
    );
};

export default Works;
