import React from 'react';
import "./about.css";//importar css da pagina
import Aboutimg from "../../assets/profile.jpeg";//importar imagem para pagina sobre
import CV from "../../assets/CV.pdf"; //importar aquivo de curriculo
import Info from "./Info"; //importar informações para pagina sobre

const About = () => {
  return (
    <div>
      <section className="about section" id="about">

        <h2 className="section__title">Vamos falar sobre a <span style={{color:"#48bc6c"}}>willbecoded</span>!</h2>
        {/* <span className="section__subtitle">Minha introdução</span> */}
        
        <div className="about__container container grid">
            <img src={Aboutimg} alt="" className="about__img"/>

            <div className="about__data">
                <Info />

                <p className="about__description">
Fundada por William Lima Alves, um desenvolvedor sediado em Timóteo, Minas Gerais, Brasil, a WillBeCoded é fruto da paixão pelo desenvolvimento web e mobile. <br/>O nome da empresa, derivado do inglês "Será Codificado", expressa o compromisso inabalável com a excelência na entrega de soluções digitais. Dedicamo-nos a proporcionar uma ampla gama de serviços, que incluem a criação de websites, landing pages e aplicativos móveis. Buscamos constantemente as melhores soluções para atender de maneira eficaz às necessidades específicas de nossos clientes.</p>
            
            </div>

        </div>
      </section>
    </div>
  )
}

export default About;
