import React from 'react';

const Frontend = () => {
  return (
    <div className="skills__content">

      <h3 className="skills__title">Stacks para melhor criação das soluções digitais de cada cliente!</h3>

      <div className="skills__box">
        
        <div className="skills_group">

          <div className="skills__data">

            <i className='bx bx-badge-check' ></i>

            <div>
              <h3 className="skills__name">Landing Pages</h3>
              <span className="skills_level">Sua presença online começa com uma landing page envolvente. Desenvolvemos páginas de destino que não só refletem sua identidade, mas também atraem visitantes e promovem conversões.</span>
            </div>

          </div>

          <div className="skills__data">

            <i className='bx bx-badge-check' ></i>

            <div>
              <h3 className="skills__name">Desenvolvimento Web e Mobile</h3>
              <span className="skills_level">Com foco na qualidade e na inovação, oferecemos serviços de desenvolvimento web e mobile personalizados, alinhados com as últimas tendências e melhores práticas da indústria.</span>
            </div>

          </div>

          <div className="skills__data">

            <i className='bx bx-badge-check'></i>

            <div>
              <h3 className="skills__name">Web Aplicações Sob Medida</h3>
              <span className="skills_level">Entendemos que cada projeto é único. Desenvolvemos web aplicações personalizadas, adaptadas às especificidades do seu negócio, proporcionando eficiência e funcionalidade.</span>
            </div>

          </div>

        </div>

        <div className="skills_group">

      
        </div>

      </div>

    </div>
  )
}

export default Frontend;
